const getToken = () => {
  return localStorage.getItem("token");
};

export const getAllRoles = async () => {
  try {
    const token = getToken();
    const response = await fetch("https://tpostapi.mytk.app/api/role/index", {
      headers: {
        Authorization: `Bearer ${token}`, // Ajoute le token d'authentification
      },
    });
    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      const result = await response.json();
      return result.roles; // Retourne le tableau de roles
    } else {
      throw new Error("Response is not JSON");
    }
  } catch (error) {
    console.error("Error fetching error:", error);
    throw error;
  }
};

export const createRole = async (role) => {
  try {
    const token = getToken();
    const response = await fetch("https://tpostapi.mytk.app/api/role/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Ajoute le token d'authentification
      },
      body: JSON.stringify(role),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Response Text:", errorText);
      throw new Error(`Network response was not ok: ${response.statusText}, body: ${errorText}`);
    }

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      return await response.json();
    } else {
      throw new Error("Response is not JSON");
    }
  } catch (error) {
    console.error("Error creating role:", error);
    throw error;
  }
};

export const updateRole = async (id, role) => {
  try {
    const token = getToken();
    const response = await fetch("https://tpostapi.mytk.app/api/role/update", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Ajoute le token d'authentification
      },
      body: JSON.stringify({ id, ...role }), // Envoie l'id avec les données de la compagnie
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      return await response.json();
    } else {
      throw new Error("Response is not JSON");
    }
  } catch (error) {
    console.error(`Error updating role with id ${id}:`, error);
    throw error;
  }
};

export const deleteRole = async (id) => {
  try {
    const token = getToken();
    const response = await fetch("https://tpostapi.mytk.app/api/role/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Ajoute le token d'authentification
      },
      body: JSON.stringify({ id }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error deleting role:", error);
    throw error;
  }
};
