import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import PropTypes from "prop-types";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { getUserWithCompany } from "layouts/user/userService";

function Basic({ setRole, setIsAuthenticated }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // État de chargement pour masquer la page temporairement
  const navigate = useNavigate();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  // Vérifie si l'utilisateur est authentifié au chargement de l'application
  useEffect(() => {
    const token = localStorage.getItem("token");
    const userRole = localStorage.getItem("userRole");

    if (token && userRole) {
      setRole(userRole);
      setIsAuthenticated(true);
      navigate("/dashboard"); // Redirige vers le dashboard si l'utilisateur est authentifié
    } else {
      navigate("/authentication/sign-in"); // Redirige vers la page de connexion si non authentifié
    }

    setLoading(false); // Arrête le chargement une fois la vérification terminée
  }, [setRole, setIsAuthenticated, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("https://tpostapi.mytk.app/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        const token = data.token;
        const userId = data.user.id;
        const userRoleName = data.user.role_name.toUpperCase();

        localStorage.setItem("token", token);
        localStorage.setItem("currentUserId", userId);
        localStorage.setItem("userRole", userRoleName);

        console.log("L'ID de l'utilisateur :", userId);
        console.log("Rôle de l'utilisateur :", userRoleName);

        const userWithCompany = await getUserWithCompany();

        console.log("Infos de l'utilisateur et de sa compagnie :", userWithCompany);

        if (userWithCompany.logo) {
          localStorage.setItem("userLogo", userWithCompany.logo);
          console.log("Logo de l'utilisateur :", userWithCompany.logo);
        } else {
          console.log("Aucune compagnie trouvée pour l'utilisateur.");
        }

        setRole(userRoleName);
        setIsAuthenticated(true);

        if (
          userRoleName === "SUPER ADMIN" ||
          userRoleName === "ADMIN" ||
          userRoleName === "AUTHOR"
        ) {
          navigate("/dashboard");
        }
      } else {
        setError(data.message || "Erreur lors de la connexion");
      }
    } catch (error) {
      setError("Une erreur est survenue. Veuillez réessayer plus tard.");
      console.error("Erreur de connexion :", error);
    }
  };

  if (loading) {
    return null; // Retourne rien pendant le chargement
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                Sign in
              </MDButton>
            </MDBox>
            {error && (
              <MDBox mt={2} mb={1}>
                <MDTypography variant="caption" color="error">
                  {error}
                </MDTypography>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

Basic.propTypes = {
  setRole: PropTypes.func.isRequired,
  setIsAuthenticated: PropTypes.func.isRequired,
};

export default Basic;
