// companyService.js

const getToken = () => {
  return localStorage.getItem("token");
};

export const getCompany = async () => {
  try {
    const token = getToken();
    const response = await fetch("https://tpostapi.mytk.app/api/compagny/index", {
      headers: {
        Authorization: `Bearer ${token}`, // Ajoute le token d'authentification
      },
    });
    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      const result = await response.json();
      return result.compagnies; // Retourne le tableau de compagnies
    } else {
      throw new Error("Response is not JSON");
    }
  } catch (error) {
    console.error("Error fetching:", error);
    throw error;
  }
};

export const createCompany = async (compagny) => {
  try {
    const token = getToken();
    const response = await fetch("https://tpostapi.mytk.app/api/compagny/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Ajoute le token d'authentification
      },
      body: JSON.stringify(compagny),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Response Text:", errorText);
      throw new Error(`Network response was not ok: ${response.statusText}, body: ${errorText}`);
    }

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      return await response.json();
    } else {
      throw new Error("Response is not JSON");
    }
  } catch (error) {
    console.error("Error creating company:", error);
    throw error;
  }
};

export const updateCompany = async (id, compagny) => {
  try {
    const token = getToken();
    const response = await fetch("https://tpostapi.mytk.app/api/compagny/update", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Ajoute le token d'authentification
      },
      body: JSON.stringify({ id, ...compagny }), // Envoie l'id avec les données de la compagnie
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      return await response.json();
    } else {
      throw new Error("Response is not JSON");
    }
  } catch (error) {
    console.error(`Error updating company with id ${id}:`, error);
    throw error;
  }
};

export const deleteCompany = async (id) => {
  try {
    const token = getToken();
    const response = await fetch("https://tpostapi.mytk.app/api/compagny/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Ajoute le token d'authentification
      },
      body: JSON.stringify({ id }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error deleting company:", error);
    throw error;
  }
};

export const getCompanyById = async () => {
  try {
    const token = getToken();
    const response =
      ("https://tpostapi.mytk.app/api/compagny/show/${companyId}",
      {
        headers: {
          Authorization: `Bearer ${token}`, // Ajoute le token d'authentification
        },
      });
    if (!response.ok) {
      throw new Error(`Erreur de récupération de la compagnie : ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Erreur dans getCompanyById :", error);
    return null;
  }
};
