const getToken = () => {
  return localStorage.getItem("token");
};

export const getUsers = async () => {
  try {
    const token = getToken();
    const response = await fetch("https://tpostapi.mytk.app/api/users", {
      headers: {
        Authorization: `Bearer ${token}`, // Ajoute le token d'authentification
      },
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const contentType = response.headers.get("content-type");
    const textResponse = await response.text(); // Lire le texte brut
    console.log("Response Text:", textResponse); // Affiche la réponse brute

    if (contentType && contentType.includes("application/json")) {
      const result = JSON.parse(textResponse);
      return result.usersD;
    } else {
      throw new Error("Response is not JSON");
    }
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const createUser = async (userData) => {
  try {
    const token = getToken();
    console.log("Données envoyées :", userData);

    const response = await fetch("https://tpostapi.mytk.app/api/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name: userData.name,
        email: userData.email,
        password: userData.password,
        password_confirmation: userData.password_confirmation, // Assurez-vous que cela est présent
        biography: userData.biography,
        phone: userData.phone,
        role_id: userData.role_id,
        compagny_id: userData.compagny_id,
      }),
    });

    // Gérer les réponses
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Error creating user");
    }

    return await response.json(); // Retourner la réponse de l'API
  } catch (error) {
    console.error("Error creating user:", error);
    throw error; // Propager l'erreur
  }
};

export const updateUser = async (userData) => {
  try {
    console.log("User data received for update:", userData);
    if (!userData || !userData.id) {
      throw new Error("Les données de l'utilisateur sont manquantes ou invalides.");
    }

    const token = getToken();
    const response = await fetch(`https://tpostapi.mytk.app/api/user/update/${userData.id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(userData),
    });

    const textResponse = await response.text();
    if (!response.ok) {
      console.error("Error response:", textResponse); // Log de l'erreur
      throw new Error(`Network response was not ok: ${textResponse}`);
    }

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      return JSON.parse(textResponse);
    } else {
      throw new Error("Response is not JSON");
    }
  } catch (error) {
    console.error(`Error updating user with id ${userData?.id}:`, error);
    throw error;
  }
};

export const deleteUser = async (userData) => {
  try {
    console.log("User data received for deletion:", userData);

    // Vérification de l'ID utilisateur avant la requête
    if (!userData || !userData.id) {
      throw new Error("User ID is required for deletion.");
    }

    const token = getToken();

    // Supprimer les associations de l'utilisateur dans usercompagnies
    await fetch(`https://tpostapi.mytk.app/api/usercompagnies/${userData.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    // Ensuite, supprimer l'utilisateur
    const response = await fetch(
      `https://tpostapi.mytk.app/api/user/delete-definitely/${userData.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to delete user");
    }

    return await response.json(); // Si vous avez besoin d'une réponse
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error; // Lancer l'erreur pour être capturée dans handleDelete
  }
};

export const getUserWithCompany = async () => {
  const userId = localStorage.getItem("currentUserId");
  const response = await fetch(`https://tpostapi.mytk.app/api/user/show/${userId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (!response.ok) {
    throw new Error("Erreur lors de la récupération des informations de l'utilisateur");
  }

  const data = await response.json();

  console.log("Données utilisateur avec compagnie :", data);

  // Récupère les noms des compagnies
  const companyNames = data.user.compagnies
    ? data.user.compagnies.map((company) => company.name)
    : [];

  // Récupère le logo de la première compagnie
  const userLogo =
    data.user.compagnies && data.user.compagnies.length > 0 ? data.user.compagnies[0].logo : null;

  return { ...data.user, logo: userLogo, companyNames }; // Retourne les noms des compagnies avec les autres informations de l'utilisateur
};
