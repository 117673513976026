import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Card,
  CardContent,
  Grid,
  Box,
  TablePagination,
  InputLabel,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { getCompany, createCompany, updateCompany, deleteCompany } from "./companyService";

const CenteredCard = styled(Card)(({ theme }) => ({
  maxWidth: 1500,
  margin: "auto",
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
}));

const getToken = () => {
  return localStorage.getItem("token");
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  flex: 1,
  textAlign: "center",
}));

const FileInput = styled("input")({
  display: "none",
});

const Compagnies = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [currentItem, setCurrentItem] = useState({ id: null, name: "", logo: "" });
  const [itemToDelete, setItemToDelete] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [error, setError] = useState("");
  const [imagePreviews, setImagePreviews] = useState([]);
  const fileInputRef = useRef(null);

  const validateImage = (image) => {
    const validTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif"];
    const maxSize = 2 * 1024 * 1024; // 2 Mo

    if (!validTypes.includes(image.type)) {
      return { valid: false, message: "Type de fichier non valide" };
    }

    if (image.size > maxSize) {
      return { valid: false, message: "Le fichier est trop volumineux" };
    }

    return { valid: true };
  };

  useEffect(() => {
    const fetchCompagnies = async () => {
      try {
        const compagnies = await getCompany();
        setData(compagnies);
      } catch (error) {
        console.error("Failed to fetch compagnies:", error);
      }
    };

    fetchCompagnies();
    const interval = setInterval(fetchCompagnies, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleOpen = (item) => {
    setCurrentItem(item || { id: null, name: "", logo: "" });
    setOpen(true);
  };

  const handleClose = () => {
    setCurrentItem({ id: null, name: "", logo: "" });
    setOpen(false);
    setError("");
  };

  const uploadImage = async (logo) => {
    const token = getToken();
    console.log("Token:", token);

    const validation = validateImage(logo);
    console.log("Image validation:", validation);

    if (!validation.valid) {
      console.error("Validation failed:", validation.message);
      throw new Error(validation.message);
    }

    const formData = new FormData();
    formData.append("logo", logo);
    console.log("FormData content:", formData.get("logo")); // Affiche l'image ajoutée au FormData

    const response = await fetch(`https://tpostapi.mytk.app/api/compagny/upload`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    console.log("Response status:", response.status);
    if (!response.ok) {
      console.error("Upload error:", await response.text());
      throw new Error("Erreur lors de l'upload");
    }

    const result = await response.json();
    console.log("Upload successful:", result);
    return result.image_url;
  };

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files);

    if (files.length === 0) {
      console.error("Veuillez sélectionner au moins un fichier.");
      return;
    }

    const validFiles = files.filter((file) => file.type.startsWith("image/"));
    if (validFiles.length === 0) {
      console.error("Aucun fichier sélectionné n'est une image.");
      return;
    }

    const newImageUrls = validFiles.map((file) => URL.createObjectURL(file));
    setImagePreviews((prev) => [...prev, ...newImageUrls]);

    for (const image of validFiles) {
      try {
        const uploadedImageUrl = await uploadImage(image);
        console.log("Image uploadée avec succès:", uploadedImageUrl);

        // Enregistrement de l'URL de l'image dans le currentItem
        setCurrentItem((prev) => ({
          ...prev,
          logo: uploadedImageUrl,
        }));
      } catch (error) {
        console.error("Erreur lors de l'upload de l'image:", error);
      }
    }
  };

  const handleSave = async () => {
    try {
      if (!currentItem.name || !currentItem.logo) {
        setError("Name and logo URL are required."); // Validation des champs
        return;
      }

      const newCompanyData = {
        name: currentItem.name,
        logo: currentItem.logo,
      };

      if (currentItem.id) {
        await updateCompany(currentItem.id, newCompanyData);
        setData(
          data.map((compagny) => (compagny.id === currentItem.id ? newCompanyData : compagny))
        );
      } else {
        const newCompany = await createCompany(newCompanyData);
        setData([...data, newCompany]);
      }
      handleClose();
    } catch (error) {
      console.error("Failed to save company:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteCompany(id);
      setData(data.filter((item) => item.id !== id));
      handleCloseConfirm();
    } catch (error) {
      console.error("Failed to delete company:", error);
    }
  };

  const handleOpenConfirm = (item) => {
    setItemToDelete(item);
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setItemToDelete(null);
    setOpenConfirm(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const ImagePreview = styled("img")({
    maxWidth: "100px",
    maxHeight: "100px",
    objectFit: "cover",
    margin: "5px",
  });

  const paginatedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const handleDeleteLogo = async () => {
    const token = getToken();

    try {
      const response = await fetch(`https://tpostapi.mytk.app/api/compagny/del_logo`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          logo: currentItem.logo, // Envoyer l'URL du logo à supprimer
        }),
      });

      if (!response.ok) {
        throw new Error("Erreur lors de la suppression du logo.");
      }

      // Suppression réussie, mise à jour de l'état local
      setCurrentItem((prev) => ({
        ...prev,
        logo: "", // Vider le champ logo
      }));

      // Optionnel : Mettre à jour la liste principale si nécessaire
      setData((prevData) =>
        prevData.map((company) =>
          company.id === currentItem.id ? { ...company, logo: "" } : company
        )
      );
    } catch (error) {
      console.error("Erreur lors de la suppression du logo:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={2}>
        <MDBox>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <CenteredCard>
                <CardContent>
                  <Button
                    color="primary"
                    onClick={() => handleOpen({ id: null, name: "", logo: "" })}
                  >
                    Add Company
                  </Button>
                  <TableContainer
                    component={Paper}
                    sx={{ marginTop: 2, width: "96%", margin: "auto" }}
                  >
                    <Table>
                      <TableRow>
                        <TableCell sx={{ width: "40%", fontWeight: "bold" }}>Name</TableCell>
                        <TableCell sx={{ width: "40%", fontWeight: "bold" }}>Logo</TableCell>
                        <StyledTableCell sx={{ width: "20%", fontWeight: "bold" }}>
                          Actions
                        </StyledTableCell>
                      </TableRow>
                      <TableBody>
                        {paginatedData.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>
                              {item.logo && (
                                <Box
                                  component="img"
                                  src={`https://tpostapi.mytk.app${item.logo}`}
                                  alt="Company Logo"
                                  sx={{ width: 50, height: 50 }}
                                />
                              )}
                            </TableCell>
                            <StyledTableCell>
                              <Button onClick={() => handleOpen(item)}>
                                <Edit />
                              </Button>
                              <Button onClick={() => handleOpenConfirm(item)}>
                                <Delete />
                              </Button>
                            </StyledTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </CardContent>
              </CenteredCard>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{currentItem.id ? "Edit Company" : "Add Company"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={currentItem.name}
            onChange={(e) => setCurrentItem({ ...currentItem, name: e.target.value })}
          />
          <Box sx={{ marginTop: 2 }}>
            <InputLabel></InputLabel>
            <FileInput
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleImageUpload}
            />
            <Button variant="contained" onClick={() => fileInputRef.current.click()}>
              Upload Logo
            </Button>
            <Box sx={{ marginTop: 2, display: "flex", alignItems: "center" }}>
              {currentItem.logo ? (
                <>
                  <ImagePreview
                    src={`https://tpostapi.mytk.app${currentItem.logo}`}
                    alt="Company logo"
                  />
                  <Button onClick={handleDeleteLogo} color="error" sx={{ marginLeft: 2 }}>
                    <Delete /> {/* Icone de suppression */}
                  </Button>
                </>
              ) : (
                <p>No image uploaded yet.</p>
              )}
            </Box>
          </Box>
          {error && <div style={{ color: "red" }}>{error}</div>}
          {error && <div style={{ color: "red" }}>{error}</div>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openConfirm} onClose={handleCloseConfirm}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>Are you sure you want to delete this company?</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm}>Cancel</Button>
          <Button onClick={() => handleDelete(itemToDelete.id)}>Delete</Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
};

export default Compagnies;
