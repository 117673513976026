import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import Icon from "@mui/material/Icon";
import { Box } from "@mui/material";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

function SignOut() {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Appel à l'API de déconnexion
    fetch("https://tpostapi.mytk.app/api/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("La déconnexion a échoué");
      })
      .then((data) => {
        console.log(data.message);
        // Suppression du token et redirection
        localStorage.removeItem("token");
        navigate("/authentication/sign-in");
      })
      .catch((error) => {
        console.error("Erreur lors de la déconnexion:", error);
      });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      sx={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Button onClick={handleLogout} variant="contained" color="primary">
        <Icon fontSize="small">login</Icon>
        Sign Out
      </Button>
    </Box>
  );
}

export default SignOut;
