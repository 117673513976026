import React, { useEffect, useState } from "react";
import { Button, TextField, Box, Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import Header from "layouts/profile/components/Header";
import { getUsers, updateUser } from "layouts/user/userService";

function Overview() {
  const [user, setUser] = useState({
    name: "",
    biography: "",
    phone: "",
    email: "",
    compagnies: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      const currentUserId = localStorage.getItem("currentUserId");

      if (!currentUserId) {
        setError("Aucun utilisateur connecté.");
        setLoading(false);
        return;
      }

      try {
        const usersData = await getUsers();
        const currentUser = usersData.find((u) => u.id === Number(currentUserId));
        if (currentUser) {
          setUser(currentUser);
        } else {
          setError("Utilisateur non trouvé.");
        }
      } catch (error) {
        setError("Erreur lors de la récupération des données de l'utilisateur");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      // Appel à la fonction d'API pour mettre à jour les données de l'utilisateur
      await updateUser(user); // Assurez-vous que la fonction `updateUser` existe dans `userService`
      setIsEditing(false); // Désactive l'édition après la mise à jour
    } catch (error) {
      setError("Erreur lors de la sauvegarde des données.");
      console.error(error);
    }
  };

  if (loading) {
    return <MDTypography variant="h6">Chargement...</MDTypography>;
  }

  if (error) {
    return (
      <MDTypography variant="h6" color="error">
        {error}
      </MDTypography>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={0} />
      <Header>
        <MDBox mt={5} mb={3}>
          {isEditing ? (
            <form onSubmit={handleSave}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Nom"
                    variant="outlined"
                    fullWidth
                    value={user.name}
                    onChange={(e) => setUser({ ...user, name: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Biographie"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={user.biography}
                    onChange={(e) => setUser({ ...user, biography: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Téléphone"
                    variant="outlined"
                    fullWidth
                    value={user.phone}
                    onChange={(e) => setUser({ ...user, phone: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    type="email"
                    value={user.email}
                    onChange={(e) => setUser({ ...user, email: e.target.value })}
                  />
                </Grid>
              </Grid>
              <Box mt={3}>
                <Button type="submit" variant="contained" color="primary">
                  Enregistrer
                </Button>
                <Button
                  type="button"
                  onClick={() => setIsEditing(false)}
                  variant="outlined"
                  color="secondary"
                  sx={{ marginLeft: "10px" }}
                >
                  Annuler
                </Button>
              </Box>
            </form>
          ) : (
            <ProfileInfoCard
              title={user.name || "Nom indisponible"}
              description={user.biography || "Biographie non renseignée"}
              info={{
                fullName: user.name || "Non renseigné",
                mobile: user.phone || "Non renseigné",
                email: user.email || "Non renseigné",
                "organisation(s)":
                  Array.isArray(user.compagnies) && user.compagnies.length > 0
                    ? user.compagnies.map((c) => c.name).join(", ")
                    : "Non renseigné",
              }}
              action={{ tooltip: "Edit Profile", onClick: handleEditClick }}
              shadow={false}
            />
          )}
        </MDBox>
      </Header>
      <MDBox mb={8} />
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
